.customers__form {
  .customers__form__input {
    padding-top: 25px;
    padding-bottom: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;

    .customers__photo__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 500px;

      .customers__input__label {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }

      .customers__photo__sub__title {
        color: #64748B;
        font-size: 12px;
        font-weight: 400;
      }

      .customers__button {
        width: 130px;
        height: 32px;
        padding: 8px;
        border-radius: 12px;
        background-color: #DD1E2D;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 24px;
      }
    }

    .customers__input__container {
      display: flex;
      flex-direction: column;

      .customers__input__label {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }

      .customers__input {
        width: 311px;
        height: 56px;
        padding: 8px 16px 8px 16px;
        border-radius: 12px;
        border: 1px solid #E2E8F0;
      }

      .customers__input__address {
        width: 654px;
        height: 56px;
        padding: 8px 16px 8px 16px;
        border-radius: 12px;
        border: 1px solid #E2E8F0;
      }

      .customers__select {
        height: 56px;
        border-radius: 12px;
      }
    }
  }

  .customers__actions__section {
    margin-top: 72px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: flex-end;

    .customers__cancel__button {
      width: 115px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      border: 1px solid #555555;
      font-weight: 700;
    }

    .customers__save__button {
      width: 176px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      background-color: #DD1E2D;
      color: white;
      font-weight: 700;
    }

    .customers__save__button:hover {
      border-color: white !important;
      color: white !important;
    }
  }
}