.image__upload__container {
  width: 129px;
  height: 180px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background-color: #fffcfc;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #dd1e2d;

  .label {
    margin: 0;
    cursor: pointer;
    border-radius: 12px;
    padding: 4px 8px;
    background-color: #DD1E2D;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .label:hover {
      border-color: white !important;
      color: white !important;
    }

  .file__upload {
    display: none;
  }

  img {
    width: 129px;
    height: 130px;
    object-fit: contain;
    margin: 1rem;

    max-height: 100%;
  }
}

.webcam__button {
  width: 140px;
  height: 32px;
  padding: 8px;
  border-radius: 12px;
  background-color: #DD1E2D;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
}
