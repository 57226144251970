.print__button {
  width: 176px;
  height: 48px;
  padding: 8px;
  border-radius: 12px;
  background-color: #DD1E2D;
  color: white;
  font-weight: 700;
  margin-right: 24px;
}

.print__button:hover {
  border-color: white !important;
  color: white !important;
}

.loan__copy {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 16px;

  .details {
    padding: 10px 64px 10px 32px;
    display: flex;
    flex-direction: row;
    gap: 80px;
    justify-content: space-between;
    border: 1px solid black;

    .company__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      gap: 6px;
      margin-right: 16px;

      p {
        margin: 0px;
        text-align: center;
      }
    }

    .customer__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 4px;

      p {
        margin: 0px;
      }
    }
  }

  .loan__summary {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    margin-top: 24px;

    .loan__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
       padding: 16px;
    }

    .loan__summary__detail {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

       .loan__detail {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
       }
    }
  }

  .item__details {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .item__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
       padding: 16px;
    }

    .loan__summary__detail {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding: 16px;

       .loan__detail {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
       }
    }
  }

  .signature__section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 32px;
  }
}