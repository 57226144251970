.sign-in-container {
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .cover-image {
    width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
  }

  .form-container {
    width: 50%;
    padding: 0 164px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.20px;
      text-align: left;
      color: #0F172A;
      margin: 0;
    }

    .subtitle {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #64748B;
    }

    .signin__input {
      width: 404px;
      height: 56px;
      padding: 8px 16px 8px 16px;
      border-radius: 12px;
      border: 1px solid #E2E8F0;
    }

    .signin__button {
      margin-top: 16px;
      width: 404px;
      height: 56px;
      padding: 8px;
      border-radius: 12px;
      background-color: #DD1E2D;
      color: white;
      font-weight: 700;
    }

    .signin__button:hover {
      border-color: white !important;
      color: white !important;
    }
  }
}
