.pledge__payment__modal {
    padding-left: 44px;
    padding-right: 44px;

    .divider {
      height: 1px;
      width: 100%;
      background-color: #F1F5F9;
      margin-top: 24px;
      margin-bottom: 24px;
    }

  .payment__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;

    .payment__input {
      width: 280px;
      height: 56px;
      padding: 8px 16px 8px 16px;
      border-radius: 12px;
      border: 1px solid #E2E8F0;
    }

    .payment__select {
      width: 280px;
      height: 56px;
      border-radius: 12px;
    }

    .payment__longinput {
      width: 560px;
      height: 56px;
      padding: 8px 16px 8px 16px;
      border-radius: 12px;
      border: 1px solid #E2E8F0;
    }

    .payment__textarea {
      width: 560px;
      padding: 8px 16px 8px 16px;
      border-radius: 12px;
      border: 1px solid #E2E8F0;
    }
  }

  .payment__actions__section {
    margin-top: 72px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: flex-end;

    .payment__cancel__button {
      width: 115px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      border: 1px solid #555555;
      font-weight: 700;
    }

    .payment__save__button {
      width: 176px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      background-color: #DD1E2D;
      color: white;
      font-weight: 700;
    }

    .payment__save__button:disabled {
      opacity: 0.5;
    }

    .payment__save__button:hover {
      border-color: white !important;
      color: white !important;
    }
  }
}