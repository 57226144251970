.investment__table__page {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .deposit__input {
    width: 200px;
    height: 56px;
  }

  .withdraw__button{
    width: 126px;
    height: 48px;
    padding: 8px;
    border-radius: 12px;
    background-color: #DD1E2D;
    color: white;
    font-weight: 400;
    margin-right: 24px;
  }

  .withdraw__button:hover {
    border-color: white !important;
    color: white !important;
    background-color: #DD1E2D !important;
  }

  .ant-table-thead .ant-table-cell {
    background: #F8FAFC !important;
    color: #94A3B8 !important;
    text-align: center !important;
  }

  .ant-table-tbody .ant-table-cell {
    text-align: center !important;
  }

  .search__button {
      width: 176px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      background-color: #DD1E2D;
      color: white;
      font-weight: 700;
    }

    .search__button:hover {
      border-color: white !important;
      color: white !important;
    }

}