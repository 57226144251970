.overview__index__page {
  background-color: white;
  .title {
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 2.25rem;
    margin-bottom: 2rem;
  }
  .loan__form__input {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;

    .loan__photo__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 311px;

      .loan__input__label {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }

      .loan__photo__sub__title {
        color: #64748b;
        font-size: 12px;
        font-weight: 400;
      }

      .loan__button {
        width: 130px;
        height: 32px;
        padding: 8px;
        border-radius: 12px;
        background-color: #dd1e2d;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 24px;
      }
    }

    .loan__input__container {
      display: flex;
      flex-direction: column;

      .error__message {
        color: #dd1e2d;
      }

      .loan__input__label {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }

      .loan__input {
        width: 311px;
        height: 56px;
        padding: 8px 16px 8px 16px;
        border-radius: 12px;
        border: 1px solid #e2e8f0;
        display: flex;
        align-items: center;
      }

      .loan__textarea {
        max-width: 311px !important;
        padding: 8px 16px 8px 16px;
        border-radius: 12px;
        border: 1px solid #e2e8f0;
      }

      .loan__select {
        height: 56px;
        border-radius: 12px;
      }
    }
  }

  .loan__items__section {
    .loan__items__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.2px;
      text-align: left;
      margin-top: 34px;
      margin-bottom: 34px;
    }

    .loan__items__table {
      display: flex;
      flex-direction: column;

      .loan__item__input {
        height: 56px;
        padding: 8px 16px 8px 16px;
        border-radius: 12px;
        border: 1px solid #e2e8f0;
      }

      .ant-table-thead .ant-table-cell {
        background: #f8fafc !important;
        color: #94a3b8 !important;
      }

      .add__item__button {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;

        .add__new__item__text {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #307df0;
          cursor: pointer;
        }
      }
    }
  }

  .loan__actions__section {
    margin-top: 72px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: flex-end;

    .loan__cancel__button {
      width: 115px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      border: 1px solid #555555;
      font-weight: 700;
    }

    .loan__save__button {
      width: 176px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      background-color: #dd1e2d;
      color: white;
      font-weight: 700;
    }

    .loan__save__button:disabled {
      opacity: 0.5;
    }

    .loan__save__button:hover {
      border-color: white !important;
      color: white !important;
    }
  }
}
