.terms__page {
  padding: 32px;
  margin: 32px;
  background-color: white;
  height: fit-content;
  margin-top: 7.5rem;

  .print__button {
    background-color: #DD1E2D;
    color: white;
    height: 48px;
    width: 176px;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 12px;
    margin-top: 16px;
  }

  @media print {
  body * {
    visibility: hidden; /* Hide all elements */
  }
  .printable-content {
    visibility: visible; /* Show only the <p> tag */
    position: absolute;
    left: 0;
    top: 0;
  }
}
}