.users__table__page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  .all-interest {
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .interest {
      background-color: #FFF;
      border-radius: 8px;
      margin: 10px;
      padding: 20px;
      text-align: left;
      min-width: 300px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      height: 380px;
      overflow-y: scroll;
    }
  }
}