.pledge__table__page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  .pledge__input__container {
    .pledge__input {
      width: 311px;
      height: 56px;
      border-radius: 12px;
      border: 1px solid #e2e8f0;
    }
  }

  .all-interest {
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .interest {
      background-color: #fff;
      border-radius: 8px;
      margin: 10px;
      padding: 20px;
      text-align: left;
      min-width: 300px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      height: 380px;
      overflow-y: scroll;
    }
  }
}
