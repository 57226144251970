.loan__recovery__index__page {
  margin: 32px;
  padding: 46px;
  background-color: white;
  margin-top: 7.5rem;
}
.generate__button {
  width: 176px;
  height: 56px;
  padding: 8px;
  border-radius: 12px;
  background-color: #DD1E2D;
  color: white;
  font-weight: 700;
}

.generate__button:disabled {
  opacity: 0.5;
}

.generate__button:hover {
  border-color: white !important;
  color: white !important;
}

.recovery__input{
  width: 200px;
  height: 56px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
}