.loan__modal {
  padding-left: 40px;
  padding-right: 40px;
  height: max-content;

  .divider {
    height: 1px;
    width: 100%;
    background-color: #F1F5F9;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .profile__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    margin-bottom: 35px;

    .profile__section__details {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .customer__name {
        font-weight: 700;
        font-size: 22px;
        color:#0F172A;
        margin: 0;
      }

      .customer__id {
        font-size: 14px;
        font-weight: 400;
        color: #94A3B8;
        margin: 0;
      }
    }
  }

  .profile__details {
    background-color: #F8FAFC;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 70px;
    width: 100%;

    .detail {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 180px;
      align-items: center;

      .detail__title {
        font-size: 14px;
        font-weight: 500;
        color: #94A3B8;
        margin: 0;
      }

      .data {
        font-size: 16px;
        font-weight: 600;
        color: #0F172A;
      }
    }

    .profile__divider {
      width: 2px;
      height: 42px;
      background-color: #E2E8F0;
    }
  }
}