.print__button {
  width: 176px;
  height: 48px;
  padding: 8px;
  border-radius: 12px;
  background-color: #DD1E2D;
  color: white;
  font-weight: 700;
  margin-right: 24px;
  margin-bottom: 32px;
}

.print__button:hover {
  border-color: white !important;
  color: white !important;
}

.payment__receipt {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-collapse: collapse;
  margin: 12px;
  padding: 20px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 12px;

    .address {
      display: flex;
      flex-direction: column; 
      justify-content: center;
      align-items: center;
      gap: 0;
    }
  }

  .loan__details{
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 10px;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .loan__detail__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      p {
        margin: 0;
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 0px;
      }
      .right {
        display: flex;
        flex-direction: column;
        gap: 0px;
      }
    }
  }

  .receipt__table {
    margin: 32px;
  }

  .signature__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 32px;
    padding-bottom: 0px;
  }

}