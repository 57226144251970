.bank__page {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .current__deposit {
    display: flex;
    flex-direction: row;
    width: max-content;
    gap: 24px;

    .current__deposit__amount {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .deposit__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;

    .deposit__input {
      width: 311px;
      height: 56px;
    }

    .deposit__select {
        height: 56px;
        border-radius: 12px;
      }
  }

  .deposit__save__button {
    background-color: #DD1E2D;
    color: white;
    height: 48px;
    width: 176px;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 12px;
    margin-top: 16px;
  }

  .deposit__save__button:hover {
    border-color: white !important;
    color: white !important;
  }

  .deposit__save__button:disabled {
    border-color: white !important;
    color: white !important;
    opacity: 0.5 !important;
  }
}
  .loan__items__table {
    display: flex;
    flex-direction: column;

    .loan__item__input {
      height: 56px;
      padding: 8px 16px 8px 16px;
      border-radius: 12px;
      border: 1px solid #E2E8F0;
    }

    .ant-table-thead .ant-table-cell {
      background: #F8FAFC !important;
      color: #94A3B8 !important;
    }

    .add__item__button {
      display: flex;
      flex-direction: row;
      gap: 8px; 
      justify-content: center;

      .add__new__item__text {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #307DF0;
        cursor: pointer;
      }
    }
  }