.pledge__form {
    padding-top: 25px;
    padding-bottom: 48px;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;

    .pledge__photo__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 311px;

      .pledge__input__label {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }

      .pledge__photo__sub__title {
        color: #64748B;
        font-size: 12px;
        font-weight: 400;
      }

      .pledge__button {
        width: 130px;
        height: 32px;
        padding: 8px;
        border-radius: 12px;
        background-color: #DD1E2D;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 24px;
      }
    }

    .pledge__input__container {
      display: flex;
      flex-direction: column;
      width: 311px;

      .pledge__input__label {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }

      .loan__tags  {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;

        .loan__tag {
          
        }
      }

      .pledge__input {
        width: 311px;
        height: 56px;
        padding: 8px 16px 8px 16px;
        border-radius: 12px;
        border: 1px solid #E2E8F0;
      }

      .pledge__textarea {
        width: 311px;
        padding: 8px 16px 8px 16px;
        border-radius: 12px;
        border: 1px solid #E2E8F0;
      }

      .pledge__select {
        height: 56px;
        border-radius: 12px;
      }
    }
  }

.pledge__items__section {
  .pledge__items__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.20px;
    text-align: left;
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .loan__items__table {
    display: flex;
    flex-direction: column;

    .loan__item__input {
      height: 56px;
      padding: 8px 16px 8px 16px;
      border-radius: 12px;
      border: 1px solid #E2E8F0;
    }

    .ant-table-thead .ant-table-cell {
      background: #F8FAFC !important;
      color: #94A3B8 !important;
    }

    .add__item__button {
      display: flex;
      flex-direction: row;
      gap: 8px; 
      justify-content: center;

      .add__new__item__text {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #307DF0;
        cursor: pointer;
      }
    }
  }
}

.pledge__actions__section {
  margin-top: 72px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;

  .pledge__cancel__button {
    width: 115px;
    height: 48px;
    padding: 8px;
    border-radius: 12px;
    border: 1px solid #555555;
    font-weight: 700;
  }

  .pledge__save__button {
    width: 176px;
    height: 48px;
    padding: 8px;
    border-radius: 12px;
    background-color: #DD1E2D;
    color: white;
    font-weight: 700;
  }

  .pledge__save__button:disabled {
      opacity: 0.5;
    }

  .pledge__save__button:hover {
    border-color: white !important;
    color: white !important;
  }
}