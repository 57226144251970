.pay__dialog_container {
  background-color: white !important;
  padding: 20px;
  border-radius: 10px;

  .pay__dialog__inputs {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
  }

  .pay__dialog__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 3.125rem;
  }
}
