.our_report__page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .all-interest {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .interest {
      background-color: #FFF;
      border-radius: 8px;
      margin: 10px;
      padding: 20px;
      text-align: left;
      min-width: 300px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      height: 700;
      width: 350px;
      overflow: scroll; 
      scrollbar-width: none; 
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .our_report__search__part {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    .rangepicker {
      height: 56px;
      width: 400px;
      padding: 8px 16px 8px 16px;
      border-radius: 12px;
      border: 1px solid #e2e8f0;
      margin-left: 5px;
    }
    .report__input {
      margin-left: 30px;
    }

    .report__button {
      width: 176px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      background-color: #DD1E2D;
      color: white;
      font-weight: 700;
    }

    .report__button:hover {
      border-color: white !important;
      color: white !important;
    }
  }
}