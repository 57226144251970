.outlined__button {
  border: 1px solid #dd1e2d;
  color: #dd1e2d;
  padding: 13px 34px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 115px;
}

.outlined__button:hover {
  border-color: #dd1e2d !important;
  color: #dd1e2d !important;
}
