.protected__layout__container {
  display: flex;
  .sidebar {
    min-width: max-content;
    max-width: max-content;
    min-height: 100vh;
    height: 100%;
    background: white;

    position: fixed;

    border-right: 1px solid #f1f5f9;

    .css-1wvake5 {
      border-right: none !important;
    }
    .css-dip3t8 {
      background-color: white !important;
    }
  }
  .header__container {
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;
    background-color: white;
    flex-grow: 1;
    position: fixed;
    width: 100vw;
    padding-right: 100px;
    z-index: 999;

    .logout__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 176px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      background-color: #DD1E2D;
      color: white;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    //min-width: 15.625rem;
    overflow-x: hidden;
  }
  .image__header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f1f5f9;
    width: 100%;
    border-right: 1px solid #f1f5f9;
    height: 100%;
    margin-right: 1.5rem;
    margin-bottom: 3rem;
    height: 5.5rem;
  }

  .list-item {
    //styleName: body/medium/medium;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.0125rem;
    text-align: left;
    margin-inline: 1rem;
    max-width: 13.625rem;
    cursor: pointer;
  }
  .list-item:hover {
    background-color: #fff9f9;
    border-radius: 0.75rem;
  }
  .list-item-active {
    //styleName: body/medium/bold;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    background-color: #fff9f9;
    border-radius: 0.75rem;
    color: #dd1e2d;
  }

  .main__container {
    margin-left: 0px;
    flex: 1;
    min-height: 100vh;
    overflow-x: auto;

    .sidebar__collapsed {
      margin-left: 80px;
    }

    .sidebar__expanded {
      margin-left: 250px;
    }

    .logout__expanded {
      padding-right: 300px;
    }

    .page__container {
      margin-top: 6.5rem;
      width: calc(100% - 4rem);
      margin-inline: 2rem;
      background-color: white;
      margin-top: 1.625rem;
      min-height: calc(100% - 8.75rem);
      border-radius: 1rem;
      padding: 44px 46px;
    }
  }
}

.full-screen-modal .ant-modal-content {
  background: rgba(255, 255, 255, 0.8); /* Dark background */
  color: white;
  height: 80vh; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-modal .ant-modal {
  max-width: 100vw; /* Full-screen width */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove padding */
}

.full-screen-modal .ant-modal-body {
  height: 100%; /* Make the modal body fill the screen */
  display: flex;
  justify-content: center;
  align-items: center;
}

