@import './variable.scss';
@import './modules/index.scss';
@import './components/index.scss';

.full__width {
  width: 100%;
}

body {
  font-family: "Inter";
}