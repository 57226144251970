.custom__button {
  background-color: #dd1e2d !important;
  border-radius: 0.75rem !important;
  color: white !important;
  //styleName: Heading/H6;
  font-family: Inter;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.375rem !important;
  text-align: left !important;
  padding-block: 1.0625rem !important;
}
