.deposit__table__page {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .deposit__input {
    width: 311px;
    height: 56px;
  }

  .ant-table-thead .ant-table-cell {
    background: #F8FAFC !important;
    color: #94A3B8 !important;
    text-align: center !important;
  }

  .ant-table-tbody .ant-table-cell {
    text-align: center !important;
  }

  .search__button {
      width: 176px;
      height: 48px;
      padding: 8px;
      border-radius: 12px;
      background-color: #DD1E2D;
      color: white;
      font-weight: 700;
    }

    .search__button:hover {
      border-color: white !important;
      color: white !important;
    }

}