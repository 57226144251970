.expense__page {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .expense__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;

    .expense__input {
      width: 311px;
      height: 56px;
    }

    .expense__select {
      height: 56px;
    }

    .expense__textarea {
      width: 311px;
    }
  }

  .expense__save__button {
    background-color: #DD1E2D;
    color: white;
    height: 48px;
    width: 176px;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 12px;
    margin-top: 16px;
  }

  .expense__save__button:hover {
    border-color: white !important;
    color: white !important;
  }
}