.pledge__details__page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 40px;

  .profile__section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 22px;
      color:#0F172A;
    }

    .pledge__number {
      font-size: 14px;
      font-weight: 400;
      color: #94A3B8;
      margin: 0;
    }
  }

  .profile__details {
    background-color: #F8FAFC;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 35px;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    gap: 32px;

    .detail {
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;
      justify-content: center;
      width: 200px;

      .detail__title {
        font-size: 14px;
        font-weight: 500;
        color: #94A3B8;
        margin: 0;
      }

      .data {
        font-size: 16px;
        font-weight: 600;
        color: #0F172A;
      }
    }

    .divider {
      width: 2px;
      height: auto;
      background-color: #E2E8F0;
    }
  }

  .closed__loan__section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 25px;
    width: 100%;

    .current__loan__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .closed__loan__title {
        font-size: 24px;
        font-weight: 700;
        color: #0F172A;
      }

      .create__loan__button {
        width: 176px;
        height: 48px;
        padding: 8px;
        border-radius: 12px;
        background-color: #DD1E2D;
        color: white;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-decoration: none;

        .plus__icon {
          width: 20px;
          height: 20px;
        }
      }

      .create__loan__button:hover {
        border-color: white !important;
        color: white !important;
      }
    }

    .closed__loan__title {
      font-size: 24px;
      font-weight: 700;
      color: #0F172A;
    }

    .closed__loan__data {
      font-size: 16px;
      font-weight: 600;
      color: #0F172A;
    }
  }
}