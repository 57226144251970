.loan__all__page {
  margin: 32px;
  padding: 46px;
  background-color: white;
  margin-top: 7.5rem;

  .title {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;

      .search__container {
        width: 413px;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0px; 

        .dropdown__select {
          .ant-select-selection-item {
            color: #64748B !important;
          }

          .ant-select-selector {
            border-radius: 12px 0px 0px 12px !important;
            border-right: none !important;
          }
        }

        .search__input {
          height: 48px;
          width: 263px;
          padding: 0px 16px, 0px 16px;
          border-radius: 0px 12px 12px 0px;
        }

        .search__icon {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}