.custom__input__text__container {
  .custom__input__text {
    padding: 8px 16px 8px 16px;
    height: 3.5rem;
  }
  label {
    display: block;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 1rem;
  }
  .error-message {
    font-size: 12px;
    color: red;
  }
}
